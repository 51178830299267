import {effect, Injectable, NgZone, signal, WritableSignal} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {EShopProfile, EShopProfileQuery} from '../../order/gql/e-shop-profile.query';

@Injectable({
  providedIn: 'root',
})
export class EcwidInitializationService {
  ready: WritableSignal<boolean> = signal(false);

  private storeId: Subject<string> = new Subject();
  private storeApi: Record<string, any>;

  constructor(
    private readonly eShopProfileQuery: EShopProfileQuery,
    private readonly zone: NgZone,
  ) {
    effect((): void => {
      if (this.ready()) {
        this.storeApi = window['Ecwid'];
      }
    });
  }

  getEShopProfile(): Observable<EShopProfile> {
    return this.eShopProfileQuery.fetch().pipe(map(profile => profile.data.eShopProfile));
  }

  getStoreId(): Observable<string> {
    return this.storeId;
  }

  setStoreId(id: string): void {
    this.storeId.next(id);
  }

  countCart(fn: Function): void {
    this.ready() ? this.storeApi.Cart.get((cart: {items: any[]}) => this.zone.run(() => fn(cart.items.length))) : fn(0);
  }

  clearCart(): void {
    if (this.ready()) {
      this.storeApi.Cart.clear();
    }
  }
}
