import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';

import {ClientSettingsService} from 'projects/shared/src/lib/storybook/client-settings/client-settings.service';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  constructor(
    private readonly clientSettingsService: ClientSettingsService,
    private readonly keycloakService: KeycloakService,
  ) {
    if (!this.keycloakService.isLoggedIn()) {
      return;
    }

    this.clientSettingsService.getClientSettings()
      .then(res => {
        if (res.clientSettings.hotjarDef.hotjarRun == 'true') {
          this.initHotjar(res.clientSettings.hotjarDef.hotjarId, res.clientSettings.hotjarDef.hotjarVersion);
        }
      });
  }

  private initHotjar(hjid: number, hjsv: number): void {
    (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
      h._hjSettings = {hjid: hjid, hjsv: hjsv};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}
