export enum SimRole {
  DASHBOARD_MENU = 'ROLE_DASHBOARD_MENU',
  SUB_ACCOUNTS_MENU = 'ROLE_SUB_ACCOUNTS_MENU',
  SIMS_MENU = 'ROLE_SIMS_MENU',
  EVENTS_MENU = 'ROLE_EVENTS_MENU',
  SIMS_PROVISIONING_MENU = 'ROLE_SIMS_PROVISIONING_MENU',
  BATCH_OPERATIONS_MENU = 'ROLE_BATCH_OPERATIONS_MENU',
  REGULAR_PLANS_MENU = 'ROLE_REGULAR_PLANS_MENU',
  POOL_PLANS_MENU = 'ROLE_POOL_PLANS_MENU',
  PACKAGES_MENU = 'ROLE_PACKAGES_MENU',
  INVOICES_MENU = 'ROLE_INVOICES_MENU',
  AVAILABILITY_ZONE_MENU = 'ROLE_AVAILABILITY_ZONE_MENU',
  PROVIDERS_MENU = 'ROLE_PROVIDERS_MENU',
  CONNECTIVITY_RATES_MENU = 'ROLE_CONNECTIVITY_RATES_MENU',
  RULE_MENU = 'ROLE_RULE_MENU',
  NOTIFICATION_MENU = 'ROLE_NOTIFICATION_MENU',
  REPORTS_MENU = 'ROLE_REPORTS_MENU',
  ORDER_SIMS_MENU = 'ROLE_ORDER_SIMS_MENU',
  BILLING_INVOICES_MENU = 'ROLE_BILLING_INVOICES_MENU',
  ORDERS_MENU = 'ROLE_ORDERS_MENU',
  DM_MENU = 'ROLE_DM_MENU',

  DASHBOARD_REVENUE_COST_PROFIT_VIEW = 'ROLE_DASHBOARD_REVENUE_COST_PROFIT_VIEW',
  SIM_PROFILE = 'ROLE_SIM_PROFILE',
  RENEWAL_STRATEGY_ADVANCED = 'ROLE_RENEWAL_STRATEGY_ADVANCED',
  SIM_EXTERNAL_PARAMS = 'ROLE_SIM_EXTERNAL_PARAMS',
  REGULAR_PLAN_EXTERNAL_PARAMS = 'ROLE_REGULAR_PLAN_EXTERNAL_PARAMS',
  POOL_PLAN_EXTERNAL_PARAMS = 'ROLE_POOL_PLAN_EXTERNAL_PARAMS',
  SIM_MANAGE_PLANS = 'ROLE_SIM_MANAGE_PLANS',
  UPLOAD_CONNECTIVITY_RATES = 'UPLOAD_CONNECTIVITY_RATES',
  INVOICE_REPORT_R = 'ROLE_INVOICE_REPORT_R',
  BILLING_ADJUSTMENTS = 'ROLE_BILLING_ADJUSTMENTS',
  USERS_SETTINGS = 'ROLE_USERS_SETTINGS',
  LABELS_SETTINGS = 'ROLE_LABELS_SETTINGS',
  TERMS_OF_USE = 'ROLE_TERMS_OF_USE',
  GL_CODES = 'ROLE_GL_CODES',
  AUDIT_TRAIL = 'ROLE_AUDIT_TRAIL',
  SETTINGS_EXTERNAL_PARAMS = 'ROLE_SETTINGS_EXTERNAL_PARAMS',
  SMS_CONTENT = 'ROLE_SMS_CONTENT',
  BILLING_INVOICE_SETTINGS = 'ROLE_BILLING_INVOICE_SETTINGS',
  DEVICE_OPERATIONS = 'ROLE_DEVICE_OPERATIONS',
  SIGNALING_EVENTS = 'ROLE_SIGNALING_EVENTS',
  DASHBOARD_SUB_ACCOUNTS = 'ROLE_DASHBOARD_SUB_ACCOUNTS',
  ACTIVATE_PLAN = 'ROLE_ACTIVATE_PLAN',
  TOP_UP_EXPIRATION_DATE = 'ROLE_TOP_UP_EXPIRATION_DATE',
  DASHBOARD_MAP = 'ROLE_DASHBOARD_MAP',
  SUPPORT_MY_TICKETS = 'ROLE_SUPPORT_MY_TICKETS',
  SUPPORT_MY_SUB_ACCOUNTS_TICKETS = 'ROLE_SUPPORT_MY_SUB_ACCOUNTS_TICKETS',
  SUB_ACCOUNT_CONTACTS = 'ROLE_SUB_ACCOUNT_CONTACTS',
  SUB_ACCOUNT_ADDRESSES = 'ROLE_SUB_ACCOUNT_ADDRESSES',
  SUB_ACCOUNT_BILLING_INVOICES = 'ROLE_SUB_ACCOUNT_BILLING_INVOICES',
  SUB_ACCOUNT_EXTERNAL_PARAMS = 'ROLE_SUB_ACCOUNT_EXTERNAL_PARAMS',
  SUB_ACCOUNT_SELF_UPLOAD = 'ROLE_SUB_ACCOUNT_SELF_UPLOAD',
  ROLE_CREATE_USER_SELF_ACCOUNT = 'ROLE_CREATE_USER_SELF_ACCOUNT',
  ROLE_CREATE_USER_SUB_ACCOUNT = 'ROLE_CREATE_USER_SUB_ACCOUNT',
  ROLE_INVOICES_W = 'ROLE_INVOICES_W',
  ACCESS_FEE = 'ROLE_ACCESS_FEE',
  PRODUCT_PLANS = 'ROLE_PRODUCT_PLANS',
  SETTINGS_MY_ACCOUNT = 'ROLE_SETTINGS_MY_ACCOUNT',
}
