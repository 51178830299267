import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {NavItemModel} from '../../models/nav-item.model';
import {CurrentAccountService} from '../../services/current-account/current-account.service';
import {ImpersonateService} from '../../services/impersonate/impersonate.service';
import {BdMixins} from '../../utilities/bd-mixins';
import {SIDE_NAV_ANIMATION_CONFIG} from './animations/side-nav-animation.config';
import {SIDENAV_REQUEST_LOGO, SidenavService} from './sidenav.service';

@Component({
  selector: 'lib-app-sidenav',
  templateUrl: './sidenav.component.html',
  animations: SIDE_NAV_ANIMATION_CONFIG,
})
export class SidenavComponent extends BdMixins.hasSubs(class {}) implements OnInit, OnDestroy {
  @Input() expandMode: boolean;
  @Input() isCollapseMenu = false;
  @Input() isSideBarClosed: boolean;
  @Input() showLogoInSidenav = false;

  @Output() toggleExpandMode: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleSideMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  expandedItemRoute: string;
  groupedItems: {[key: string]: NavItemModel[];};
  impersonated: boolean;
  logoPath: string;

  private readonly closeMenuIcon: string = './assets/favicon/menu-close-icon.svg';
  private readonly openMenuIcon: string = './assets/favicon/menu-open-icon.svg';
  private readonly subMenuIcon: string = './assets/favicon/submenu.svg';

  constructor(
    @Inject(SIDENAV_REQUEST_LOGO) private readonly requestLogo: boolean,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly currentAccountService: CurrentAccountService,
    private readonly service: SidenavService,
    private readonly router: Router,
    private readonly impersonateService: ImpersonateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchSidenavItems();
    this.iconRegistry.addSvgIcon('menu-open-icon', this.sanitizer.bypassSecurityTrustResourceUrl(this.openMenuIcon));
    this.iconRegistry.addSvgIcon('menu-close-icon', this.sanitizer.bypassSecurityTrustResourceUrl(this.closeMenuIcon));
    this.iconRegistry.addSvgIcon('submenu', this.sanitizer.bypassSecurityTrustResourceUrl(this.subMenuIcon));
    this.impersonated = this.impersonateService.isImpersonated();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  fetchSidenavItems(): void {
    const sidenavItems$ = this.service.getSidenavItems();
    this.subs$.push(
      forkJoin({
        account: this.requestLogo ? this.currentAccountService.get() : of(null),
        sidenavItems: sidenavItems$,
      }).subscribe(({account, sidenavItems}) => {
        if (account) {
          if (account?.logoPath) {
            this.logoPath = account.logoPath;
          }
        }

        const filteredItems = sidenavItems.filter(item =>
          ((item.title !== 'rates' && account?.isHideConnectivityRates) || (item.title !== 'connectivity' && !account?.isHideConnectivityRates)),
        );

        this.groupedItems = filteredItems.reduce((acc, item) => {
          const category = item.rootCategory || 'default';
          acc[category] = acc[category] || [];
          acc[category].push(item);
          return acc;
        }, {} as Record<string, any[]>);

        filteredItems.forEach(item => {
          if (this.router.url.includes(item.route)) {
            this.expandedItemRoute = item.route;
          }
        });
      }),
    );
  }

  toggleSideBar(): void {
    this.toggleSideMenu.emit(this.expandMode);
    this.toggleExpandMode.emit();
  }
}
