import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {ClientSettingsQuery, ClientSettingType} from './client-settings.query';

@Injectable({
  providedIn: 'root',
})
export class ClientSettingsService {
  clientSettingsCache: ClientSettingType;

  constructor(
    private readonly clientSettingsQuery: ClientSettingsQuery,
  ) {}

  getClientSettings(): Promise<ClientSettingType>  {
    if (this.clientSettingsCache) {

      return of(this.clientSettingsCache).toPromise();
    }

    return this.clientSettingsQuery.fetch().pipe(
      tap(res => this.clientSettingsCache = res.data),
      map(res => res.data),
    ).toPromise();
  }
}
