import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import {DocumentNode} from 'graphql';
import gql from 'graphql-tag';

import {CurrentAccountModel} from './current-account.model';

export interface CurrentAccountQueryResponse {
  currentAccount: CurrentAccountModel;
}

@Injectable({
  providedIn: 'root',
})
export class CurrentAccountQuery extends Query<CurrentAccountQueryResponse> {
  document: DocumentNode = gql`
    query {
      currentAccount {
        uuId
        name
        description
        isRoot
        logoPath
        userId
        avatarPath
        username
        currencyId
        path
        isLastAllowedLevel
        termsOfUsePath
        isShowingSmsContent
        activityIndication
        allowOfferDelegation
        isHideConnectivityRates
        isImeiLockEnabled
        isAllowedImeiLock
        isSmb
        primaryEmail {
          type
          email
        }
      }
    }
  `;
}
