export  const mapValues = (obj, callback) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, callback(value, key, obj)])
  );
};

export const debounce = (func: Function, delay: number, options: { leading?: boolean } = {}): Function =>  {
  let timerId;
  let invoked = false;

  return function (...args) {
    const context = this;
    if (!timerId && options.leading && !invoked) {
      func.apply(context, args);
      invoked = true;
    }
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      invoked = false;
      func.apply(context, args);
    }, delay);
  };
};

export const merge = (target: Record<string, any>, source: Record<string, any>): Record<string, any> => {
  const result = {...target};

  if (source === undefined) {
    return result;
  }
  for (const key of Object.keys(source)) {
    if (Array.isArray(source[key])) {
      result[key] = Array.isArray(result[key]) ? [...result[key], ...source[key]] : [...source[key]];
    } else if (source[key] && typeof source[key] === 'object' && source[key] !== null) {
      result[key] = merge(result[key] || {}, source[key]);
    } else {
      result[key] = source[key];
    }
  }
  return result;
};

export const getValueAtPath = (obj: any, path: string | string[]): any => {
  const pathArray = Array.isArray(path) ? path : path.split('.');
  return pathArray.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, obj);
};

export const uniqBy = (arr, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = item => item[prop];
  }
  return arr.filter(
    (x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y))
  );
};

export const unionBy = (arr, ...args) => {
  let iteratee = args.pop()
  if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
  }
  return arr
    .concat(...args)
    .filter(
      (x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y))
    )
}

export const isEmpty = (obj: any): boolean => {
  if (obj == null) return true;
  if (Array.isArray(obj) || typeof obj === 'string') return obj.length === 0;
  if (obj instanceof Map || obj instanceof Set) return obj.size === 0;
  if (typeof obj === 'object') {
    return Object.keys(obj).length === 0;
  }
  return true;
};

export const inRange = (num: number, rangeStart: number, rangeEnd: number = 0): boolean => {
  const [min, max] = rangeStart < rangeEnd ? [rangeStart, rangeEnd] : [rangeEnd, rangeStart];
  return num >= min && num < max;
};

export const isEqual = (value: any, other: any): boolean => {
  if (value === other) {
    return true;
  }

  if (value == null || other == null || typeof value !== typeof other) {
    return false;
  }

  if (Array.isArray(value) && Array.isArray(other)) {
    if (value.length !== other.length) {
      return false;
    }
    return value.every((item, index) => isEqual(item, other[index]));
  }
  if (value instanceof Date && other instanceof Date) {
    return value.getTime() === other.getTime();
  }
  if (value instanceof RegExp && other instanceof RegExp) {
    return value.toString() === other.toString();
  }
  if (typeof value === 'object' && typeof other === 'object') {
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    if (valueKeys.length !== otherKeys.length) {
      return false;
    }
    return valueKeys.every(key => other.hasOwnProperty(key) && isEqual(value[key], other[key]));
  }

  if (typeof value === 'number' && typeof other === 'number') {
    return isNaN(value) && isNaN(other);
  }
  return false;
};

export const chunk = (arr: any[], chunkSize: number = 1): any[] => {
  const result: any[] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

export const deepClone = <T>(obj: T, seen = new WeakMap()): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (seen.has(obj)) {
    return seen.get(obj) as T;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }
  if (obj instanceof Map) {
    const clonedMap = new Map();
    seen.set(obj, clonedMap);
    obj.forEach((value, key) => {
      clonedMap.set(deepClone(key, seen), deepClone(value, seen));
    });
    return clonedMap as unknown as T;
  }
  if (obj instanceof Set) {
    const clonedSet = new Set();
    seen.set(obj, clonedSet);
    obj.forEach(value => {
      clonedSet.add(deepClone(value, seen));
    });
    return clonedSet as unknown as T;
  }
  if (Array.isArray(obj)) {
    const clonedArray = [];
    seen.set(obj, clonedArray);
    obj.forEach(item => {
      clonedArray.push(deepClone(item, seen));
    });
    return clonedArray as unknown as T;
  }
  const clonedObj = {} as T;
  seen.set(obj, clonedObj);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone(obj[key], seen);
    }
  }
  return clonedObj;
};

export const isString = (a: unknown): a is string =>
  Object.prototype.toString.call(a) === '[object String]';

export const flatten = (array: any[]): any[] => {
  if (array == null || !Array.isArray(array)) {
    return [];
  }
  let result: any[] = [];
  for (const value of array) {
    if (Array.isArray(value)) {
      result.push(...value);
    } else {
      result.push(value);
    }
  }
  return result;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (
    (obj1 === null || obj1 === undefined || obj1 === "") &&
    (obj2 === null || obj2 === undefined || obj2 === "")
  ) {
    return true;
  }

  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null) {
    return false;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
