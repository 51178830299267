import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule, TooltipPosition} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'lib-info-icon-button',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './info-icon-button.component.html',
  styleUrls: ['./info-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoIconButtonComponent {
  @Input() tooltipText: string;
  @Input() icon: string = 'flo-tooltip';
  @Input() matTooltipPosition: TooltipPosition = 'right';
  @Input() additionalClassPanel: string;
}
