import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {ExpressionNode} from '@rsql/ast';
import {parse} from '@rsql/parser';

import {ConditionConfig} from '../../../../../../shared/src/lib/models/actions.config';
import {PushErrorNotification} from '../../../../../messaging/src/lib/notifications/store/notifications.actions';
import {ConditionOperatorsEnum} from '../_shared/enums/condition-operators.enum';
import {ConditionOptionsEnum} from '../_shared/enums/condition-options.enum';

import {ConditionTypesEnum} from '../_shared/enums/condition-types.enum';

import {ConditionMapper} from './condition.service';

@Injectable({
  providedIn: 'root',
})

export class ConditionRsqlMapperService<T extends Record<string, any>> implements ConditionMapper {
  type = ConditionTypesEnum.RSQL;

  constructor(
    private readonly store: Store,
  ) {}

  getConditionValue(elem: T, condition: ConditionConfig): boolean {
    return this.parseDefaultCondition(elem, parse(condition['value']));
  }

  private getComparisonValue(elem: T, condition: ExpressionNode): boolean {
    const value = condition['right']['value'];
    const selector = condition['left']['selector'];
    const selectorName = selector.includes('.') ? selector.split('.')[0] : selector;
    const selectorValue = selector.includes('.') ? this.splitSelector(elem, selector.split('.')) : elem[selector];
    let conditionValue: boolean;

    if (Object.prototype.hasOwnProperty.call(elem, selectorName)) {
      if (condition['operator'] === ConditionOperatorsEnum.EQUALS) {
        conditionValue = String(selectorValue) === String(value);
      } else if (condition['operator'] === ConditionOperatorsEnum.NOTEQUAL) {
        conditionValue = String(selectorValue) !== String(value);
      }
    } else {
      this.store.dispatch(new PushErrorNotification(
        {text: 'the-selector-is-missing-on-the-element-please-recheck-the-actions-configuration', params: {selector}}));
    }

    return conditionValue;
  }

  private parseDefaultCondition(elem: T, condition: ExpressionNode): boolean {
    let conditionValue; let leftValue; let rightValue: boolean;

    if (condition['type'] === ConditionOptionsEnum.COMPARISON) {
      conditionValue = this.getComparisonValue(elem, condition);
    } else if (condition['type'] === ConditionOptionsEnum.LOGIC) {
      if (condition['left']['type'] === ConditionOptionsEnum.COMPARISON) {
        leftValue = this.getComparisonValue(elem, condition['left']);
      } else if (condition['left']['type'] === ConditionOptionsEnum.LOGIC) {
        leftValue = this.parseDefaultCondition(elem, condition['left']);
      }
      if (condition['right']['type'] === ConditionOptionsEnum.COMPARISON) {
        rightValue = this.getComparisonValue(elem, condition['right']);
      } else if (condition['right']['type'] === ConditionOptionsEnum.LOGIC) {
        rightValue = this.parseDefaultCondition(elem, condition['right']);
      }

      if (condition['operator'] === ConditionOperatorsEnum.OR) {
        conditionValue = leftValue || rightValue;
      } else if (condition['operator'] === ConditionOperatorsEnum.AND) {
        conditionValue = leftValue && rightValue;
      }
    }

    return Boolean(conditionValue);
  }

  private splitSelector(elem: any, splitValues: string[]): any {
    if (elem == null) {
      return undefined;
    }

    if (splitValues.length === 0) {
      return elem;
    }

    const [currentKey, ...remainingKeys] = splitValues;

    if (Object.prototype.hasOwnProperty.call(elem, currentKey)) {
      const nextElem = elem[currentKey];
      return this.splitSelector(nextElem, remainingKeys);
    } else {
      return undefined;
    }
  }
}
