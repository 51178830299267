<lib-header-standalone></lib-header-standalone>

<form [formGroup]="form">
  <h2>{{'create-your-account' | translate}}</h2>

  <h3>{{'account-details' | translate}}</h3>

  <div class="form-group">
    <div>
      <label for="account-name">{{'account-name' | translate}}</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" id="account-name" formControlName="accountName" class="form-control"
               (focusout)="isExistAccount()">
      </mat-form-field>
      <mat-error *ngIf="form.get('accountName').errors?.exist">
        {{
          'entity-with-this-field-already-exists' | translate : {
            entity: ('subAccount' | translate),
            field: ('name' | translate)
          }
        }}
      </mat-error>
      <mat-error *ngIf="form.get('accountName').hasError('pattern')">
        {{'sub-account-name-special-symbols-error-msg' | translate}}
      </mat-error>
    </div>
    <div>
      <label for="country">{{'country' | translate}}</label>
      <mat-form-field appearance="outline">
        <mat-select id="country" formControlName="country">
          <mat-option *ngFor="let subAccount of subAccounts" [value]="subAccount">{{subAccount.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <h3>{{'user-details' | translate}}</h3>

  <div class="form-group">
    <div>
      <label for="userName">{{'full-name' | translate}}</label>
      <mat-form-field appearance="outline">
        <input matInput type="userName" id="userName" formControlName="userName" class="form-control">
      </mat-form-field>
    </div>
    <div>
      <label for="email">
        {{'email' | translate}}
        <lib-info-icon-button tooltipText="registration-primary-email-tooltip"></lib-info-icon-button>
      </label>

      <mat-form-field appearance="outline">
        <input matInput type="email" id="email" formControlName="email" class="form-control"
               (focusout)="isExistEmail()">
      </mat-form-field>
      <mat-error *ngIf="form.get('email').errors?.exist">{{'email-exists' | translate}}</mat-error>
      <mat-error *ngIf="form.get('email').errors?.email">{{'incorrect-email-address' | translate}}</mat-error>
      <mat-error *ngIf="form.get('email').errors?.domainError && !form.get('email').errors?.email">
        {{'invalid-domain' | translate}}
      </mat-error>
    </div>
  </div>

  <div class="form-group">
    <div>
      <label for="password">{{'password' | translate}}</label>
      <mat-form-field appearance="outline">
        <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password"
               class="form-control">
        <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="form.get('password').touched && form.get('password').invalid">
        <lib-password-policies-errors [errors]="form.get('password').errors"></lib-password-policies-errors>
      </mat-error>
    </div>
    <div>
      <label for="confirmPassword">{{'confirm-password' | translate}}</label>
      <mat-form-field appearance="outline">
        <input matInput [type]="hide ? 'password' : 'text'" id="confirmPassword" formControlName="confirmPassword"
               class="form-control">
        <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="form.get('confirmPassword').errors?.mustMatch">
        {{'passwords-must-match' | translate}}
      </mat-error>
    </div>
  </div>
  <div class="buttons">
    <div class="kyc-verification">
      <button flo-button
              mat-ripple
              special="in-table"
              size="small"
              [disabled]="form.invalid"
              (click)="openKycVerification()">
        {{'customer-verification' | translate}}
      </button>

      <div class="flo-custom-popup kyc-tooltip" *ngIf="form.valid && disableSignUp">
        <span> {{'in-order-to-complete-the-registration-you-must-verify-your-details-as-a-customer' | translate}}</span>
        <button flo-button
                mat-ripple
                [isUpperCase]="false"
                (click)="skipVerification()"> {{'skip' | translate}}
        </button>
      </div>
      <div *ngIf="!disableSignUp && !skipVerificationFlag" class="list-text-typography success-message">
        <mat-icon svgIcon="flo-success"></mat-icon>
        {{'customer-verified-successfully' | translate}}
      </div>
    </div>
    <button flo-button (click)="onSubmit()"
            [disabled]="form.invalid || disableSignUp">{{'sign-up' | translate}}
    </button>
  </div>
</form>

<lib-loading-spinner mode="spinner" *ngIf="waiting"></lib-loading-spinner>
<mess-messaging *ngIf="messaging"></mess-messaging>
