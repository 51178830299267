import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'activeFilters',
})
export class ActiveFiltersPipe implements PipeTransform {
  transform(value: Record<string, unknown>, hideFilters?: string[]): string[] {
    if (hideFilters) {
      const filteredValue: Record<string, unknown> = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (!hideFilters.includes(key)) {
            filteredValue[key] = value[key];
          }
        }
      }
      value = filteredValue;
    }

    if (!value) {
      return [];
    }

    return this.getActiveFilters(value);
  }

  private getActiveFilters(filtersValue: Record<string, unknown>): string[] {
    return Object.keys(filtersValue).filter(key => this.hasActiveFilter(filtersValue[key] as Record<string, unknown>));
  }

  private hasActiveFilter(filterValue: Record<string, unknown> | Record<string, unknown>[]): boolean {
    if (!filterValue) {
      return false;
    }

    if (filterValue instanceof Date) {
      return true;
    }

    if (Array.isArray(filterValue) && typeof filterValue[0] === 'object') {
      filterValue = filterValue.filter(value => {
        return Object.values(value).some(v => v !== null);
      });
      if (filterValue.length === 0) {
        return false;
      }
    }

    return Object.values(filterValue).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (typeof value === 'object' && value !== null) {
        return this.getActiveFilters(value as Record<string, unknown>);
      }

      return !!value;
    });
  }
}
