import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {PopoverModule} from 'projects/modules/rater/src/lib/_shared/components/popover/popover.module';
import {ClickerDirectiveModule} from 'projects/shared/src/lib/directives/clicker/clicker-directive.module';
import {DomElementValidatorDirectiveModule} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';
import {RandomClassDirectiveModule} from 'projects/shared/src/lib/directives/random-class/random-class-directive.module';
import {DynamicFormModule} from 'projects/shared/src/lib/dynamic-form/dynamic-form.module';
import {BdDatePipeModule} from 'projects/shared/src/lib/pipes/bd/bd-date/bd-date-pipe.module';
import {FirstLetterPipeModule} from 'projects/shared/src/lib/pipes/bd/first-letter/first-letter-pipe.module';
import {NullOrUndefinedHandlerPipeModule} from 'projects/shared/src/lib/pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';
import {OrderedKeyValuePipeModule} from 'projects/shared/src/lib/pipes/bd/ordered-key-value/ordered-key-value-pipe.module';
import {RandomGradientPipeModule} from 'projects/shared/src/lib/pipes/bd/random-gradient/random-gradient-pipe.module';
import {SliceWithDotsPipeModule} from 'projects/shared/src/lib/pipes/bd/slice-with-dots/slice-with-dots-pipe.module';
import {TimeAgoPipeModule} from 'projects/shared/src/lib/pipes/bd/time-ago/time-ago-pipe.module';

import {FloButtonComponent} from '../../../../../shared/src/lib/components/elements/flo-button/flo-button.component';
import {FloTruncateTooltipComponent} from '../../../../../shared/src/lib/components/flo-truncate-tooltip/flo-truncate-tooltip.component';
import {MenuMultilevelModule} from '../../../../../shared/src/lib/components/menu-multilevel/menu-multilevel.module';
import {UniversalFunctionPipeModule} from '../../../../../shared/src/lib/pipes/universal-function-pipe/universal-function-pipe.module';

import {AvatarColumnCellComponent} from './_shared/components/data-column/avatar-column-cell/avatar-column-cell.component';
import {DateColumnCellComponent} from './_shared/components/data-column/date-column-cell/date-column-cell.component';
import {IconColumnCellComponent} from './_shared/components/data-column/icon-column-cell/icon-column-cell.component';
import {ProgressBarColumnCellComponent} from './_shared/components/data-column/progress-bar-column-cell/progress-bar-column-cell.component';
import {TextColumnCellComponent} from './_shared/components/data-column/text-column-cell/text-column-cell.component';
import {ModuleV2Config} from './_shared/configs/module-v2.config';
import {BdPaginatorIntl} from './_shared/customisation/bd-paginator.intl';
import {ActiveFiltersModule} from './_shared/pipes/active-filters/active-filters.module';
import {ActiveFiltersPipe} from './_shared/pipes/active-filters/active-filters.pipe';
import {ToColorModule} from './_shared/pipes/to-color/to-color.module';
import {ToIconModule} from './_shared/pipes/to-icon/to-icon.module';
import {ToLabelModule} from './_shared/pipes/to-label/to-label.module';
import {TABLE_CONFIG} from './_shared/utilities/table-tokens';
import {ActionsConfigServiceModule} from './actions-config-service/actions-config-service.module';
import {DisplayedColumnsDialogComponent} from './displayed-columns-dialog/displayed-columns-dialog.component';
import {DefaultFiltersDialogComponent} from './filters-dialog/default-filters-dialog/default-filters-dialog.component';
import {LazyTableComponent} from './lazy-table/lazy-table.component';
import {TableComponent} from './table/table.component';
import {TableService} from './table.service';
import {MatDividerModule} from '@angular/material/divider';
import {InfoIconButtonComponent} from "projects/shared/src/lib/storybook/info-icon-button/info-icon-button.component";

@NgModule({
  declarations: [
    TableComponent,
    LazyTableComponent,
    DefaultFiltersDialogComponent,
    DisplayedColumnsDialogComponent,
    TextColumnCellComponent,
    AvatarColumnCellComponent,
    DateColumnCellComponent,
    IconColumnCellComponent,
    ProgressBarColumnCellComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCardModule,
    TranslateModule,
    TimeAgoPipeModule,
    RandomGradientPipeModule,
    NullOrUndefinedHandlerPipeModule,
    SliceWithDotsPipeModule,
    ToIconModule,
    BdDatePipeModule,
    OrderedKeyValuePipeModule,
    FirstLetterPipeModule,
    UniversalFunctionPipeModule,
    RandomClassDirectiveModule,
    DynamicFormModule,
    MatChipsModule,
    ToLabelModule,
    ClickerDirectiveModule,
    DomElementValidatorDirectiveModule,
    ReactiveFormsModule,
    ActiveFiltersModule,
    ActionsConfigServiceModule,
    MatRippleModule,
    MenuMultilevelModule,
    ToColorModule,
    FloButtonComponent,
    PopoverModule,
    FloTruncateTooltipComponent,
    MatDividerModule,
    InfoIconButtonComponent
],
  exports: [
    TableComponent,
    LazyTableComponent,
  ],
})
export class TableModule {

  static forRoot(moduleConfig: ModuleV2Config): ModuleWithProviders<TableModule> {
    return {
      ngModule: TableModule,
      providers: [
        TableService,
        ActiveFiltersPipe,
        {provide: TABLE_CONFIG, useValue: moduleConfig},
        {provide: MatPaginatorIntl, useClass: BdPaginatorIntl},
      ],
    };
  }

}
