import {ExpirationStrategyTypeRaterEnum} from 'projects/shared/src/lib/enums/expiration-strategy-type-rater.enum';
import {ExpirationStrategyUnitEnum} from 'projects/shared/src/lib/enums/expiration-strategy-unit.enum';

import {
  ExternalParameterInterface,
} from '../_shared/components/external-parameters/interfaces/external-parameter.interface';

import {PlanZoneSetItemModel} from '../availability-zones/models/plan-zone-set-item-model';

import {LinkedProductOfferingsInterface} from './shared/components/linked-offers/linked-product-offerings.interface';
import {RateTypeEnum} from './shared/enums/rate-type.enum';
import {RenewalIntervalEnum} from './enums/renewal-interval.enum';
import {RecurringTypeStrategyEnum} from '../../../../shared/src/lib/enums/recurring-type-strategy.enum';
import {ImsiProviderView} from './shared/interfaces/imsi-provider-view.interface';

export interface ProductOfferingModel {

  activeSimsAmount: number;
  customerAccountId: string;
  productOfferingId: string;
  name?: string;
  planType?: PlanTypes;
  customRateStrategy?: RateTypeEnum;
  externalParameters?: ExternalParameterInterface[];
  priceValue?: number;
  priceCurrency?: string;
  renewalStrategy?: string;
  recurringType?: RenewalIntervalEnum;
  recurringTypeValue?: string;
  recurringTypeUnits?: string;
  recurringTypeStrategy?: RecurringTypeStrategyEnum;
  effectiveDateTo?: string;
  expirationPeriodType?: ExpirationStrategyUnitEnum;
  expirationPeriodUnits?: string;
  expirationStrategyType: ExpirationStrategyTypeRaterEnum;
  dataAllowance?: number;
  dataAllowanceUnit?: string;
  dataLimit?: number;
  dataLimitUnit?: string;
  smsAllowance?: number;
  smsLimit?: number;
  moneyAllowance?: number;
  moneyAllowanceCurrency?: string;
  poolType?: string;
  designatedCustomerAccountId?: string;
  designatedCustomerAccountName?: string;
  nestedOfferAllowanceSmsBalanceId?: string;
  nestedOfferAllowanceDataBalanceId?: string;
  nestedProductOffersIds?: string[];
  linkedProductOfferings?: LinkedProductOfferingsInterface[];
  linkedOffersIds?: string[];
  assignedSims?: number;
  billingPlanId?: number;
  totalDataAllowance?: number;
  totalSmsAllowance?: number;
  currentDataUsage?: number;
  currentSmsUsage?: number;
  usageWithRate?: boolean;
  isProrated?: boolean;
  isAttachedToPackage?: boolean;
  zoneSets?: {id: string; name: string;}[];
  zoneSetsFull?: PlanZoneSetItemModel[];
  allowedImsiProviders?: ImsiProviderView[];
  isIncludingAccessFee?: boolean;
}

export enum PlanTypes {
  RATE = 'RATE',
  CUSTOM_RATE = 'CUSTOM_RATE',
  USAGE = 'USAGE',
  MONEY = 'MONEY',
  POOL = 'POOL',
  PRODUCT = 'PRODUCT',
}

export enum PlanModes {
  sellPlan = 'sellPlan',
  buyPlan = 'buyPlan',
}

export interface SubscriberOfferForPoolPlan {
  subscriber?: {
    id: string;
  };
  balanceAggregators?: {
    subscriberBalance: {
      balance: number;
      currency: string;
      id: string;
    };
    value: number;
  }[];
  productOffering?: {
    customFields: {[key: string]: string;};
    offerTerm: {
      allowance: number;
    };
  };
  nestedOffers?: SubscriberOfferForPoolPlan[];
}
