import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {Apollo} from 'apollo-angular';

import gql from 'graphql-tag';
import {
  PaginatedResponseModel,
  PaginationRequestModel,
  SearchRequestModel,
} from 'projects/bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/models/api-request.model';
import {PageQueryResponse} from 'projects/shared/src/lib/absctract/utils/gql/interfaces/page-query-response.interface';
import {CrudService} from 'projects/shared/src/lib/abstract-section-v2/providers/crud.service';
import {SimModel} from 'projects/shared/src/lib/models/sim.model';
import {UniquenessWithBackValidationResponse} from 'projects/shared/src/lib/utilities/types/uniqueness-with-back-validation.response';
import {UniquenessWithBackValidationService} from 'projects/shared/src/lib/utilities/types/uniqueness-with-back-validation.serivce';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {PlanModes, ProductOfferingModel} from '../plan.model';

import {PoolPlanDto} from '../pool-plans/pool-plans-dtos/pool-plan.dto';

import {PlanUpdateInterface} from '../shared/interfaces/plan-update.interface';
import {TariffUpdateInterface} from '../shared/interfaces/tariff-update.interface';

import {BillingPlanDto} from './billing-plan-dto/billing-plan.dto';

import {BillingPlanLogic} from './billing-plan.logic';
import {CheckBillingPlanNameUniquenessQuery} from './gql/check-billing-plan-name-uniqueness.query';
import {ApolloQueryResult, FetchResult} from '@apollo/client';

@Injectable()
export class BillingPlansService extends CrudService<BillingPlanDto> implements UniquenessWithBackValidationService {
  billingPlanMode: PlanModes = PlanModes.sellPlan;

  constructor(
    public http: HttpClient,
    public apollo: Apollo,
    private readonly logic: BillingPlanLogic,
    private readonly checkBillingPlanNameUniquenessQuery: CheckBillingPlanNameUniquenessQuery,
    protected readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super(http, {
      apiPrefix: '',
      apiPath: {
        getOne: '',
        getMany: '',
        post: '',
        put: '',
        delete: '',
      },
    });
  }

  checkUniqueness(value: string): Observable<UniquenessWithBackValidationResponse | null> {
    return this.checkBillingPlanNameUniquenessQuery.fetch({name: value}).pipe(
      map(({data}) => !data?.checkBillingPlanNameUniqueness ? {name: value} : null),
    );
  }

  createBillingPlan(billingPlan: any): Observable<FetchResult<unknown>> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ($billingPlan: CreateIndividualOfferInput!) {
              createBillingPlan(billingPlan: $billingPlan)
          }`,
      variables: {billingPlan},
    });
  }

  updateBillingPlan(updatePayload: PlanUpdateInterface): Observable<FetchResult<string>> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ($updatePayload: UpdateIndividualOfferInput!) {
              updatePlan(updatePayload: $updatePayload)
          }`,
      variables: {updatePayload},
    });
  }

  updateTariff(tariff: TariffUpdateInterface): Observable<FetchResult<unknown>> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ($tariff: TariffUpdateInput!) {
              updateTariff(tariff: $tariff)
          }`,
      variables: {tariff},
    });
  }

  deleteBillingPlan(billingPlan: BillingPlanDto): Observable<FetchResult<unknown>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation ($planId: ID!, $planType: String, $accountId: String) {
          deletePlan(planId: $planId, planType: $planType, accountId:  $accountId)
        }`,
      variables: {planId: billingPlan.productOfferingId},
    });
  }

  getAvailablePlansList(childCustomerAccountId?: string, attachedPoolPlanIdsSet?: Set<string>): Observable<BillingPlanDto[]> {
    return this.apollo.query<{getAvailablePlans: ProductOfferingModel[];}>({
      query: this.logic.queryListByChildCustomerAccountId,
      variables: {childCustomerAccountId},
      fetchPolicy: 'no-cache',
    }).pipe(
      map(({data}) => {
        data.getAvailablePlans.sort((a, b) => a.name.localeCompare(b.name));
        const availablePlans = data.getAvailablePlans.map(value => new BillingPlanDto(value));

        if (attachedPoolPlanIdsSet) {
          return availablePlans.filter(plan => !attachedPoolPlanIdsSet.has(plan.productOfferingId));
        }

        return availablePlans;
      }),
    );
  }

  getBillingPlanSubscribers(
    billingPlanId: string,
    pagination: PaginationRequestModel,
    sort: Sort,
    search: SearchRequestModel,
  ): Observable<PaginatedResponseModel<SimModel>> {
    const searchByField = search?.text ? {[search.field]: `${search.field}=="${search.text}"`} : {};
    const pageOptions: Record<string, any> = {
      requestParams: {
        ...searchByField,
      },
      pagination,
      sort,
    };

    const mode = this.activatedRoute.snapshot.queryParams['mode'];

    if (mode === PlanModes.buyPlan) {
      pageOptions.requestParams['fromParentProductOfferingId'] = [billingPlanId];
    } else {
      pageOptions.requestParams['toChildProductOfferingId'] = [billingPlanId];
    }

    return this.apollo.query<any>({
      query: this.logic.querySubscribers,
      variables: {pageOptions},
    }).pipe(map(res => res.data?.sims));
  }

  getList(): Observable<BillingPlanDto[]> {
    const createdByMe = this.billingPlanMode === PlanModes.sellPlan;

    return this.apollo.query<{regularPlans: ProductOfferingModel[];}>({
      query: this.logic.queryList,
      variables: {createdByMe},
      fetchPolicy: 'no-cache',
    }).pipe(
      map(({data}) => data.regularPlans.map(value => new BillingPlanDto(value))),
    );
  }

  getOne(planId: string, createdByMe?: boolean): Observable<BillingPlanDto> {
    return this.apollo.query<{regularPlanById: ProductOfferingModel[];}>({
      query: this.logic.queryOne,
      variables: {createdByMe, planId},
    }).pipe(
      map(({data}) => new BillingPlanDto(data.regularPlanById[0])),
    );
  }

  pageQuery(): {fetch: (variable: PageQueryResponse<BillingPlanDto>) => Observable<ApolloQueryResult<PageQueryResponse<BillingPlanDto>>>;} {
    return {
      fetch: incomingVariables =>
        this.apollo.watchQuery<PageQueryResponse<BillingPlanDto>>({
          query: this.logic.getPlansPage,
          variables: incomingVariables,
        }).valueChanges.pipe(first()),
    };
  }

  plansPageQuery(isPoolPlanType: boolean, createdByMe: boolean): {fetch: (variable: PageQueryResponse<BillingPlanDto | PoolPlanDto>) =>
  Observable<ApolloQueryResult<PageQueryResponse<BillingPlanDto | PoolPlanDto>>>;} {
    return {
      fetch: incomingVariables =>
        this.apollo.watchQuery<PageQueryResponse<BillingPlanDto | PoolPlanDto>>({
          query: this.logic.plansPage,
          variables: {params: incomingVariables['params'], isPoolPlanType, createdByMe},
        }).valueChanges.pipe(first()),
    };
  }
}
