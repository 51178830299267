import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {SimRole} from 'projects/sim/src/app/_shared/enums/sim-roles.enum';

import {ACTION_ROLES, ROLE, SUPPORT_ROLES} from '../../enums/role.enum';
import {ImpersonateService} from '../impersonate/impersonate.service';

@Injectable({providedIn: 'root'})
export class KeycloakWrapperService {
  private readonly channel: BroadcastChannel = new BroadcastChannel('logout-channel');

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly impersonateService: ImpersonateService,
  ) {
    this.autoLogout();
  }

  getUserRoles(): string[] | ROLE[] | SimRole[] {
    return this.impersonateService.isImpersonated() ?
      this.impersonateService.getUsersRoles() : this.keycloakService.getUserRoles();
  }

  isUserInRole(role: ROLE | SimRole): boolean {
    return this.impersonateService.isImpersonated() ?
      this.impersonateService.getUsersRoles().includes(role) : this.keycloakService.isUserInRole(role);
  }

  hasActionRole(): boolean {
    return this.hasRoles(ACTION_ROLES);
  }

  hasSupportRole(): boolean {
    return this.hasRoles(SUPPORT_ROLES);
  }

  hasRoles(roles: ROLE[]): boolean {
    return roles.reduce((has, role) => this.isUserInRole(role) || has, false);
  }

  isRoot(): boolean {
    return this.isUserInRole(ROLE.ROOT);
  }

  logout(): void {
    this.impersonateService.unImpersonate();
    this.channel.postMessage('logout');
    this.keycloakService.logout(window.location.origin);
  }

  private autoLogout(): void {
    this.channel.onmessage = (event: MessageEvent<string>) => {
      if (event.data === 'logout') {
        this.impersonateService.unImpersonate();
        this.keycloakService.logout(window.location.origin);
      }
    };
  }
}
