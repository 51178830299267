import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface ClientSettingType {
  clientSettings: {
    envName: string;
    maxNumberOfPlansToAttach: number;
    externalParametersLimitation: {[key: string]: number;};
    hotjarDef:{hotjarId: number, hotjarVersion: number, hotjarRun: string};
  };
}

@Injectable({
  providedIn: 'root',
})
export class ClientSettingsQuery extends Query<ClientSettingType> {
  document = gql`
      query {
          clientSettings {
              envName
              maxNumberOfPlansToAttach
              externalParametersLimitation
              hotjarDef {
                hotjarId
                hotjarVersion
                hotjarRun
              }
          }
      }
  `;
}
