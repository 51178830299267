import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectComponent} from './select.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AtPipeModule} from '../../../pipes/funda/at/at-pipe.module';
import {JoinPipeModule} from '../../../pipes/funda/join/join-pipe.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [SelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatSelectModule,

    TranslateModule,

    AtPipeModule,
    JoinPipeModule
  ],
  exports: [SelectComponent]
})
export class SelectComponentModule {
}
