import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectionListComponent} from './selection-list.component';
import {AtPipeModule} from '../../../pipes/funda/at/at-pipe.module';
import {JoinPipeModule} from '../../../pipes/funda/join/join-pipe.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';


@NgModule({
  declarations: [SelectionListComponent],
  imports: [
    CommonModule,
    MatListModule,
    AtPipeModule,
    JoinPipeModule,
    ReactiveFormsModule
  ],
  exports: [SelectionListComponent]
})
export class SelectionListComponentModule { }
